<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4">
        <b-col>
          <b-card no-body>
            <b-card-header class="border-0">
              <h3 class="mb-0">{{ $t("Search") }}</h3>
            </b-card-header>
            <b-card-body>
              <b-form>
                <b-row>
                  <b-col>
                    <base-input :label="$t('From Date')">
                      <flat-pickr
                        :config="pickr_config"
                        class="form-control"
                        :placeholder="$t('From Date')+'...'"
                        v-model='from_date'
                      >
                      </flat-pickr>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('To Date')">
                      <flat-pickr
                        :config="pickr_config"
                        class="form-control"
                        :placeholder="$t('To Date')+'...'"
                        v-model='to_date'
                      >
                      </flat-pickr>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Department')">
                      <select class="form-control" v-model="cur_department_idn" @change="department_changed">
                        <option></option>
                        <option
                          v-for="(dpt, idx) in departmentlist"
                          :key="idx"
                          :value="dpt.IDn"
                        >
                          {{ dpt.Name1 }}-{{ dpt.Name2 }}-{{ idx }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Category')">
                      <select class="form-control" v-model="cur_category_idn">
                        <option></option>
                        <option
                          v-for="(ct, idx) in search_categorylist"
                          :key="idx"
                          :value="ct.IDn"
                        >
                          {{ ct.Name1 }}-{{ ct.Name2 }}-{{ idx }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Store')">
                      <select class="form-control" v-model="cur_store_id" >
                        <option></option>
                        <option
                          v-for="(loc, idx) in storelist"
                          :key="idx"
                          :value="loc.LocationID"
                        >
                          {{ loc.LocationName }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Summary Only')">
                      <b-form-checkbox
                        class="form-checkbox"
                        v-model="summary_only"
                        :value="true"
                        :unchecked-value="false"
                        switch>
                        <!-- {{ $t('Summary Only')}} -->
                      </b-form-checkbox>
                    </base-input>
                  </b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                </b-row>

                <b-row class="justify-content-lg-center">
                  <b-col class="text-center" lg="2">
                    <b-button variant="primary" @click="form_search">{{
                      $t("Search")
                    }}</b-button>
                  </b-col>
                  <!-- <b-col class="text-center" lg="2">
                    <a v-bind:href="export_url" class="btn btn-primary" target="_blank">{{ $t("Export") }}</a>
                  </b-col> -->
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <b-card>
        <b-card-body :style="{overflowX: 'auto' }">
        <div v-bind:style="{ width: report_width }">
          <div v-if="list_data">
              <b-row v-bind:style="{ backgroundColor: 'lightGrey' }">
                  <b-col><div v-bind:style="markStyleObject">{{ special_mark }}</div></b-col>
                  <b-col :key='"col_name_" + col_name_key' v-for='(col_name, col_name_key) in list_data["column_name"]'>
                      <div v-bind:style="headerStyleObject">{{ col_name }}</div>
                  </b-col>
              </b-row>
              <b-row :key='"cate_" + cate_key' v-for='(cate, cate_key) in list_data["category_data_list"]'>
                  <b-col>
                      <div v-bind:style="titleStyleObject">{{ title_list[cate_key] }}</div>
                        <div>
                          <b-row :key='"sub_row_" + sub_row_key' v-for='(sub_row, sub_row_key) in cate[Object.keys(cate)[0]]'>
                              <b-col><div v-bind:style="nameStyleObject">{{ sub_row_key }}</div></b-col>
                              <b-col :key='"col_data_" + col_data_key' v-for='(col_data, col_data_key) in cate'>
                                  <div :style="{ textAlign: 'right' }">{{ col_data[sub_row_key] }}</div>
                              </b-col>
                          </b-row>
                      </div>
                  </b-col>
              </b-row> 
          </div>
          <div v-else>
            {{ $t('No Records') }}
          </div>

        </div>
        </b-card-body>
      </b-card>
    </b-container>


  </div>
</template>
<script>
import { Table, TableColumn, Button, Pagination } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Pagination.name]: Pagination,
    [flatPicker.name]: flatPicker,
  },
  data() {
    return {
      pickr_config: {
        dateFormat: "Y-m-d",
        maxDate: "today"
      },

      markStyleObject: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '20px',
      },
      headerStyleObject: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '18px',
      },
      titleStyleObject: {
        textAlign: 'left',
        textDecoration: 'underline',
        fontWeight: 'bold',
        fontSize: '22px',
      },
      nameStyleObject: {
        textAlign: 'left',
        fontWeight: 'bold',
      },

      token: "",
      user_id: 0,
      only_self: null,

      list_data: null,
      title_list: null,
      special_mark: null,

      departmentlist: null,
      search_categorylist: [],
      storelist: null,
      
      from_date: null,
      to_date: null,
      cur_department_idn: null,
      cur_category_idn: null,
      cur_store_id: null,

      export_url: "",

      summary_only: true,
      report_width: null,
    };
  },
  watch: {},
  computed: {
    pdfBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    }
  },
  methods: {
    department_changed() {
      this.cur_category_idn = null;
      if (!this.cur_department_idn) {
        this.search_categorylist = [];
        return;
      }
      for (var i=0; i<this.departmentlist.length; i++) {
        if (this.cur_department_idn == this.departmentlist[i].IDn) {
          // find it
          this.search_categorylist = this.departmentlist[i].categorylist;
          break;
        }
      }
    },
    async get_list_data() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      var url = "";
      if (axios.defaults.baseURL != undefined) {
        url = axios.defaults.baseURL;
      }
      url += "/Api/Web/Pos_sales_by_category/pdf?";
      url += "token=" + this.token;
      url += "&user_id=" + this.user_id;
      url += "&FromDate=" + this.from_date;
      url += "&ToDate=" + this.to_date;

      bodyFormData.append("FromDate", this.from_date);
      bodyFormData.append("ToDate", this.to_date);
      if (this.cur_department_idn) {
        bodyFormData.append("DepartmentIDn", this.cur_department_idn);
        url += "&DepartmentIDn=" + this.cur_department_idn;
      }
      if (this.cur_category_idn) {
        bodyFormData.append("CategoryIDn", this.cur_category_idn);
        url += "&CategoryIDn=" + this.cur_category_idn;
      }
      if (this.cur_store_id) {
        bodyFormData.append("StoreID", this.cur_store_id);
        url += "&StoreID=" + this.cur_store_id;
      }
      if (this.summary_only) {
        bodyFormData.append("SummaryOnly", this.summary_only);
        url += "&SummaryOnly=" + this.summary_only;
      }

      this.export_url = url;

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Pos_sales_by_category",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.onlyself = rt.data.onlyself;
              if (!that.storelist) that.storelist = rt.data.storelist;
              if (!that.departmentlist) that.departmentlist = rt.data.departmentlist;

              that.list_data = rt.data.list_data;
              that.data_column_count = rt.data.data_column_count;
              that.title_list = rt.data.title_list;
              that.special_mark = rt.data.special_mark;
              that.report_width = String((rt.data.data_column_count + 1) * 240) + 'px';
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
          else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_category_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (this.cur_department_idn != null && this.cur_department_idn && this.cur_department_idn != '') {
          bodyFormData.append("DepartmentIDn", this.cur_department_idn);
      }
      else{
        this.search_categorylist = [];
        return;
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Category/search",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.search_categorylist = rt.data.categorys;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    form_cancel() {
      this.$router.push("/store");
    },
    async form_search() {
      this.get_list_data();
    },
    get_today() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      return yyyy + '-' + mm + '-' + dd;
    },
    async init_data() {
      this.from_date = this.get_today();
      this.to_date = this.get_today();
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      this.get_list_data();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
</style>
